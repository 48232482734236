<template>
    <DxDataGrid
        class="pt-2 table-page"
        id="table_NhatKyTruyenTai_DanhSachBaoCao"
        ref="table_NhatKyTruyenTai_DanhSachBaoCao"
        :show-borders="true"
        :data-source="Params.DataTable"
        :remote-operations="true"
        :allow-column-resizing="true"
        :column-auto-width="false"
        @cellHoverChanged="onCellHoverChanged"
        :loadPanel="{ showIndicator: false, showPane: false, text: '' }"
        key-expr="IdBaoCao"
        height="calc(100vh - 170px)"
    >
        <DxPaging :page-size="parseInt($i18n.t('Settings.PageSize'))" />
        <!-- <DxGrouping :auto-expand-all="false"/> -->
        <DxScrolling
            :mode="$i18n.t('Settings.DxScrolling_Mode')"
            :row-rendering-mode="
                $i18n.t('Settings.DxScrolling_RowRenderingMode')
            "
        />
        <DxColumnFixing :enabled="true" />
        <DxHeaderFilter :visible="true" />
        <slot name="dx-column"></slot>
        <DxColumn
            :fixed="true"
            alignment="left"
            data-field="LoaiTruyenTai"
            caption="Loại truyền tải"
            :minWidth="450"
        ></DxColumn>
        <DxColumn
            :allowFiltering="false"
            alignment="left"
            data-type="date"
            format="hh:mm:ss dd/MM/yyyy"
            data-field="ThoiGianTruyenTai"
            caption="Thời gian truyền tải"
            :minWidth="500"
        ></DxColumn>
        <DxColumn
            :allowFiltering="false"
            data-field="MaLoi"
            caption="Mã lỗi"
            data-type="int"
            :minWidth="250"
        ></DxColumn>
        <DxColumn
            alignment="left"
            data-field="MoTaLoi"
            caption="Mô tả lỗi"
            :minWidth="300"
            :header-filter="{ dataSource: dataSourceStatus }"
        ></DxColumn>

        <!-- Hover button -->
        <DxColumn
            cssClass="column-button-hover"
            data-field="Detail"
            caption="Trạng thái"
            width="1"
            cell-template="buttons-cell"
        ></DxColumn>

        <template #buttons-cell="{ data }">
            <div class="buttons">
                <DxButton
                    id
                    icon="mdi mdi-eye"
                    @click="XemChiTiet(true, data.data)"
                    v-if="
                        $Helper.Common.CheckRole(
                            $i18n.t('Quyen.BaoCaoBenXe'),
                            $i18n.t('Quyen.BieuDoBaoCaoNhatKyTruyenTai')
                        )
                    "
                ></DxButton>
            </div>
        </template>
    </DxDataGrid>
</template>
<script>
import { DxDataGrid, DxButton } from "devextreme-vue";
import {
    DxColumn,
    DxPaging,
    DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
} from "devextreme-vue/data-grid";

export default {
    components: {
        DxDataGrid,
        DxPaging,
        DxScrolling,
        DxColumnFixing,
        DxHeaderFilter,
        DxColumn,
        DxButton,
    },
    props: {
        Params: { type: Object, default: {} },
    },
    data() {
        return {};
    },
    methods: {
        XemChiTiet(visible, data) {
            this.emitter.emit("nhatkytruyentai-detail", {
                Visible: visible,
                Data: data,
            });
        },
        onCellHoverChanged(e) {
            this.$Helper.Common.table({ Element: e, Buttons: true }).HoverRow();
        },
        dataSourceStatus(data) {
            let self = this;
            data.dataSource.postProcess = (results) => {
                results = self.Params.DataError;
                return results;
            };
        },
    },
    mounted() {
        let self = this;
        this.emitter.on("excel", function () {
            let init = {
                Element:
                    self.$refs.table_NhatKyTruyenTai_DanhSachBaoCao.instance,
                WorkSheet: "Danh Sách",
                FileName: "Bao-Cao-Nhat-Ky-Truyen-Tai",
            };
            self.$Helper.Common.excel(init).Export();
        });
        this.emitter.on("reload", function () {
            let datagrid =
                self.$refs.table_NhatKyTruyenTai_DanhSachBaoCao.instance;
            datagrid.clearFilter();
        });
    },
    beforeUnmount() {
        this.emitter.off("excel");
    },
};
</script>
<style scoped>
>>> .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="5"] {
    text-align: right !important;
}
>>> .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    font-size: 12px !important;
}
</style>