<template>
    <popups-vue :Params="data_props" :Dialog="dialog" @Click="click">
        <template v-slot:popups>
            <div class="row-detail pr-2">
                <div class="xs3">Tên bến truyền tải</div>
                <div class="xs9">{{ data.TenBenXe }}</div>
            </div>
            <div class="row-detail pt-5 pr-2">
                <div class="xs3">Phần mềm truyền tải</div>
                <div class="xs9">{{ data.TenPhanMem }}</div>
            </div>
            <div class="row pt-5">
                <div class="xs6 mr-3">
                    <div class="row-detail">
                        <div class="xs6">Loại truyền tải</div>
                        <div class="xs6 pl-2">{{ data.LoaiTruyenTai }}</div>
                    </div>
                </div>
                <div class="xs6 ml-3">
                    <div class="row-detail">
                        <div class="xs6">Trạng thái truyền tải</div>
                        <div class="xs6 pl-2">
                            {{ data.TrangThaiTruyenTai }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-5">
                <div class="xs6 mr-3">
                    <div class="row-detail">
                        <div class="xs6">Thời gian truyền tải</div>
                        <div class="xs6 pl-2">{{ data.ThoiGianTruyenTai }}</div>
                    </div>
                </div>
                <div class="xs6 ml-3">
                    <div class="row-detail">
                        <div class="xs6">Mã lỗi</div>
                        <div class="xs6 pl-2">{{ data.MaLoi }}</div>
                    </div>
                </div>
            </div>
            <div class="row-detail pt-5 pr-2">
                <div class="xs3">Mô tả lỗi</div>
                <div class="xs9">{{ data.MoTaLoi }}</div>
            </div>
            <h3 class="font-16 pt-5 pb-2 font-regular pl-2">
                Dữ liệu nhận được
            </h3>
            <DxTextArea
                :height="245"
                :read-only="true"
                :value="data.DuLieu"
                class="custom-text-area"
                style="
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    width: 100%;
                    word-wrap: break-word;
                    padding-left: 20px;
                "
            />
        </template>
    </popups-vue>
</template>
<script>
import PopupsVue from "../../_Common/Popups/Popups.vue";
import { DxTextArea } from "devextreme-vue";
export default {
    components: {
        PopupsVue,
        DxTextArea
    },
    data() {
        return {
            dialog: false,
            data_props: {
                Title: "Chi tiết nhật ký",
                Width: "952",
                Height: "calc(100vh - 100px)",
            },
            data: {
                TenBenXe: "",
                TenPhanMem: "",
                LoaiTruyenTai: "",
                TrangThaiTruyenTai: "",
                ThoiGianTruyenTai: "",
                MaLoi: "",
                MoTaLoi: "",
                DuLieu: "",
            },
        };
    },
    methods: {
        click(param) {
            console.log("click -> param", param);
            if (!!param) {
            }
            this.dialog = false;
        },
    },
    created() {
       this.emitter.on("nhatkytruyentai-detail", (data) => {
            console.log("created -> data", data);
            this.dialog = data.Visible;
            // data.Data.DuLieu = {
            //     qqq: "alkdfj",
            //     eee: 123,
            //     www: ["djkls", "jfls"],
            //     ttt: false
            // };
            // let dulieu_nhandc = [];
            //     let row = "";
            // for (const key in data.Data.DuLieu) {
            //     if (data.Data.DuLieu.hasOwnProperty(key)) {
            //         let value = data.Data.DuLieu[key];
            //         switch (typeof(value)) {
            //             case "number":
            //                 row = key+": "+"<span style='color:#D10909'>"+value+"</span></br>"
            //                 dulieu_nhandc.push(row);
            //                 break;
            //             case "string":
            //                 row = key+": "+"<span style='color:#13B000'>"+value+"</span></br>"
            //                 dulieu_nhandc.push(row);
            //                 break;
            //             case "boolean":
            //                 row = key+": "+"<i class='mdi mdi-checkbox-marked' style='color:#0620EE'></i><span style='color:#FFAA00'>"+value+"</span></br>"
            //                 dulieu_nhandc.push(row);
            //                 break;
            //             case "array":
            //                 row = key+": "+"<span style='color:#FFAA00'>"+value+"</span></br>"
            //                 dulieu_nhandc.push(row);
            //                 break;
            //             case "undefined":
            //                 break;

            //             default:
            //                 break;
            //         }
            //     }
            // }
            // data.Data.DuLieu = dulieu_nhandc;
            if (!!data.Data.DuLieu) {
                try {
                    data.Data.DuLieu = JSON.stringify(
                        JSON.parse(data.Data.DuLieu),
                        null,
                        "\t"
                    );
                } catch (error) {
                    console.log(
                        "created -> error",
                        error,
                        "Không phải định dạng JSON"
                    );
                }
            }
            this.data = data.Data;
        });
    },
};
</script>

<style scoped>
.custom-text-area.dx-texteditor:before,
.custom-text-area.dx-texteditor:after {
    border-bottom: unset !important;
}
</style>