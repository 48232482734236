<template>
    <div>
        <DxToolbar :height="56" class="toolbar">
            <DxItem location="before" locate-in-menu="never">
                <template #default>
                    <router :breadcrumbsItems="breadcrumbsItems" />
                </template>
            </DxItem>
            <DxItem location="after">
                <template #default>
                    <search-vue
                        @search="searchs"
                        @model="modelSearch"
                        id="textbox_NhatKyTruyenTai_Timkiem"
                    />
                </template>
            </DxItem>
        </DxToolbar>
        <div class="pl-3">
            <div
                class="row justify-space-between"
                style="border-bottom: 1px solid #ddd"
            >
                <DxValidationGroup ref="formValidation">
                    <div class="row align-center pt-2 pb-4">
                        <span class="mr-2 font-16" id="label_NhatKyTruyenTai_Tu"
                            >Từ</span
                        >
                        <DxDateBox
                            v-model="model.DateFrom"
                            displayFormat="dd/MM/yyyy"
                            dropDownButtonTemplate="customIcon"
                            validationMessageMode="always"
                            id="datebox_NhatKyTruyenTai_TuNgay"
                            class="datebox-baocao"
                            stylingMode="underlined"
                            :useMaskBehavior="true"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Không được để trống!"
                                />
                                <DxRangeRule
                                    :max="maxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="minDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                                <DxRangeRule
                                    v-model:max="model.DateTo"
                                    message="Từ ngày phải nhỏ hơn đến ngày"
                                />
                            </DxValidator>
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDateBox>
                        <span
                            style="
                                border: 1px solid #707070;
                                height: 2px;
                                width: 10px;
                            "
                            class="mx-2"
                        ></span>
                        <DxDateBox
                            v-model="model.DateTo"
                            validationMessageMode="always"
                            displayFormat="dd/MM/yyyy"
                            dropDownButtonTemplate="customIcon"
                            id="datebox_NhatKyTruyenTai_DenNgay"
                            class="datebox-baocao"
                            stylingMode="underlined"
                            :useMaskBehavior="true"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Không được để trống!"
                                />
                                <DxRangeRule
                                    :max="maxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="minDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                                <DxRangeRule
                                    :min="model.DateFrom"
                                    message="Từ ngày phải lớn hơn đến ngày"
                                />
                            </DxValidator>
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                        </DxDateBox>
                        <DxButton
                            class="ml-3"
                            :icon="$i18n.t('icon.search')"
                            text="Lọc"
                            styling-mode="outlined"
                            id="button_NhatKyTruyenTai_Loc"
                            :rtlEnabled="true"
                            @click="validate"
                        />
                    </div>
                </DxValidationGroup>
                <div class="pt-3 pr-1">
                    <DxButton
                        :icon="$i18n.t('icon.format_list_checkbox')"
                        id="button_NhatKyTruyenTai_Loc"
                        class="icon-list"
                        :hint="$i18n.t('string.list')"
                    />
                    <DxButton
                        :icon="$i18n.t('icon.chart_bar')"
                        id="button_NhatKyTruyenTai_Loc"
                        @click="
                            $router.push(
                                '/Bao-Cao-Thong-Ke/Nhat-Ky-Truyen-Tai/Bieu-Do'
                            )
                        "
                        :hint="$i18n.t('string.chart')"
                        v-if="
                            $Helper.Common.CheckRole(
                                $i18n.t('Quyen.BaoCaoBenXe'),
                                $i18n.t('Quyen.BieuDoBaoCaoNhatKyTruyenTai')
                            )
                        "
                    />
                </div>
            </div>
            <toolbar-vue
                :Params="data_from_api"
                @selectionChange="selectionChange"
                @reload="reloadFuc"
            />
            <datatable-vue :Params="data_from_api" />
            <detail-vue />
        </div>
    </div>
</template>
<script>
import moment from "moment";
import router from "@/components/_Common/Components/router";
import ToolbarVue from "@/components/BaoCaoThongKe/NhatKyTruyenTai/Toolbar";
import searchVue from "@/components/_Common/Components/Search";
import DatatableVue from "@/components/BaoCaoThongKe/NhatKyTruyenTai/Datatable";
import DetailVue from "@/components/BaoCaoThongKe/NhatKyTruyenTai/Detail.vue";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import { DxDateBox, DxButton, DxValidator } from "devextreme-vue";
import { DxRangeRule, DxRequiredRule } from "devextreme-vue/data-grid";
import { DxValidationGroup } from "devextreme-vue/validation-group";

export default {
    // Permission: $PermText("DanhSach", "BCNhatKyTruyenTai"),
    layout: "application",
    components: {
        router,
        ToolbarVue,
        DatatableVue,
        DetailVue,
        searchVue,
        DxToolbar,
        DxItem,
        DxValidationGroup,
        DxDateBox,
        DxValidator,
        DxRequiredRule,
        DxRangeRule,
        DxValidator,
        DxButton,
        DxDateBox,
        DxButton,
    },
    data() {
        return {
            url: this.$i18n.t("url.NhatKyTruyenTaiList"),
            urlXemChiTietBCViPham: this.$i18n.t("url.XemChiTietBCViPham"),
            url_capdo: this.$i18n.t("url.CapDoTaiKhoan"),
            url_loi: this.$i18n.t("url.NhatKyTruyenTaiDanhSachLoi"),
            url_select_so: this.$i18n.t("url.DungChungDanhSachSo"),
            url_select_ben: this.$i18n.t("url.DungChungDanhSachBenTheoSo"),
            maxDate: new Date(this.$i18n.t("DefaultNumBer.MaxDate")),
            minDate: new Date(this.$i18n.t("DefaultNumBer.MinDate")),
            id_table: "IdNhatKyTruyenTai",
            model: {
                DateFrom: new Date(),
                DateTo: new Date(),
                Search: "",
                IdSos: 0,
                IdBens: 0,
            },
            btn: [
                {
                    name: "password",
                    location: "after",
                    options: {
                        icon: "mdi mdi-calendar",
                        type: "normal",
                        onClick: () => {},
                    },
                },
            ],
            data_from_api: {
                Type: null,
                DataSelect: {
                    SelectSo: [],
                    SelectBenXe: [],
                },
                DataTable: [],
                DataError: [],
            },
            breadcrumbsItems: [
                {
                    id: "breadcrums_NhatKyTruyenTai_BaoCaoThongKe",
                    text: this.$i18n.t("stringRouter.nodeBCTK"),
                    disabled: true,
                    to: "",
                },
                {
                    id: "breadcrums_NhatKyTruyenTai_NhatKyTruyenTaiDuLieu",
                    text: this.$i18n.t("stringRouter.brancheNKTT"),
                    disabled: true,
                    to: "",
                },
            ],
        };
    },
    methods: {
        async getData() {
            try {
                let query = {
                    tuNgay: this.$Helper.ConvertToTimeTicks(
                        this.model.DateFrom
                    ),
                    denNgay: this.$Helper.ConvertToTimeTicks(this.model.DateTo),
                    idSos: this.model.IdSos,
                    idBens: this.model.IdBens,
                    txtTimKiem: this.model.Search || "",
                };
                this.data_from_api.DataTable = (
                    await this.$store.dispatch(
                        "BaoCaoThongKe/Get_BaoCaoNhatTrinh",
                        {
                            url:
                                JSON.parse(
                                    localStorage.getItem("DataBC_ViPham")
                                ) != null
                                    ? this.urlXemChiTietBCViPham
                                    : this.url,
                            query: query,
                        }
                    )
                ).Store;
            } catch (error) {
                console.log(
                    "🚀 ~ file: index.vue ~ line 259 ~ getData ~ error",
                    error
                );
            }
        },
        async getDataError() {
            try {
                this.$startLoading;
                let rs = await this.$Core.Api.BaoCaoBenXe(this.url_loi).Get();
                if (rs.Data.State == "Success") {
                    let data = rs.Data.Data.map(
                        ({ MaLoi, TenLoi, Value = [] }) => ({
                            maLoi: MaLoi,
                            text: TenLoi,
                            value: ["MaLoi", "=", MaLoi],
                        })
                    );
                    this.data_from_api.DataError = data;
                }
            } catch (error) {
                console.log("🚀 error", error);
            } finally {
                this.$stopLoading;
            }
        },
        reloadFuc() {
            this.startValue = new Date();
            this.endValue = new Date();
            this.model = {
                DateFrom: new Date(),
                DateTo: new Date(),
                Search: "",
                IdSos: 0,
                IdBens: 0,
            };
            this.getData();
        },
        searchs() {
            this.getData();
        },
        modelSearch(search) {
            this.model.Search = search;
        },
        selectionChange(data) {
            this.model.IdSos = data.SelectSo;
            this.model.IdBens = data.SelectBenXe;
        },
        validate(e) {
            let result = this.$refs.formValidation.instance.validate();
            if (!!result.isValid) {
                this.getData();
            }
        },
    },
    created() {
        this.data_from_api.Type =
            this.$Core.Auth.TokenParsed?.CauHinhCu?.LoaiDonVi;
    },
    watch: {},
    async mounted() {
        console.log(this.data_from_api, ":lkdsjflksdjflsjlk");
        if (JSON.parse(localStorage.getItem("DataBC_ViPham")) != null) {
            this.model.DateFrom = new Date(
                JSON.parse(localStorage.getItem("DataBC_ViPham")).DateFrom
            );
            this.model.DateTo = new Date(
                JSON.parse(localStorage.getItem("DataBC_ViPham")).DateTo
            );
            this.model.IdBens = JSON.parse(
                localStorage.getItem("DataBC_ViPham")
            ).IdBenXe;
            this.getData(false, false);
        }
        // this.getData();
        this.getDataError();
    },
};
</script>
<style>
.datebox-baocao .dx-dropdowneditor-button {
    width: 100%;
}
.datebox-baocao
    .dx-datebox.dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input,
.dx-datebox:not(.dx-texteditor-empty).dx-auto-width.dx-dropdowneditor-button-visible.dx-editor-underlined
    .dx-texteditor-input {
    padding-right: unset;
    max-width: 150px;
}
</style>
<style scoped>
/* >>> #btn-filter.dx-button .dx-button-content {
    flex-direction: row-reverse;
    display: flex;
}
>>> #btn-filter.dx-button.dx-button-has-icon.dx-button-has-text .dx-icon {
    margin-left: 8px;
    margin-right: 0px;
    line-height: 22px;
    font-size: 21px;
} */
>>> .icon-list i {
    color: #0620ee;
}
</style>
