<template>
    <DxPopup
        :visible="Dialog"
        :drag-enabled="Params.Drag"
        :close-on-outside-click="Params.Outside"
        :show-title="Params.ShowTitle"
        :width="Params.Width"
        :height="Params.Height ? Params.Height : '690'"
    >
        <h1 class="text-xs-center font-medium font-20 pb-4">
            {{ Params.Title }}
        </h1>
        <div class="btn-close-popup">
            <DxButton icon="close" @click="click(false)" id="btn-close" />
        </div>
        <DxScrollView
            id="popups"
            class="popups"
            show-scrollbar="onScroll"
            reach-bottom-text="Updating..."
        >
            <slot name="popups"></slot>
        </DxScrollView>
        <div class="row justify-space-between pt-3">
            <DxButton
                text="HỦY"
                style="text-transform: uppercase; color: #0620ee"
                :style="Params.StyleButtonLeft"
                styling-mode="text"
                @click="click()"
            ></DxButton>
            <DxButton
                text="ĐỒNG Ý"
                style="
                    text-transform: uppercase;
                    background-color: #0620ee;
                    color: white;
                "
                styling-mode="contained"
                :style="Params.StyleButtonRight"
                @click="click(true)"
            ></DxButton>
        </div>
    </DxPopup>
</template>
<script>
import { DxPopup, DxButton, DxScrollView } from "devextreme-vue";
export default {
    components: {
        DxPopup,
        DxButton,
        DxScrollView,
    },
    props: {
        Params: {
            type: Object,
            default: {
                Drag: false,
                Outside: false,
                ShowTitle: false,
                Title: "",
                Width: "70%",
                Height: "200px",
                StyleButtonLeft: "",
                StyleButtonRight: "",
            },
        },
        Dialog: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    methods: {
        click(param) {
            this.$emit("Click", param);
        },
    },
};
</script>


<style scoped>
#popups.popups {
    height: calc(100% - 100px);
}
</style>